import {
	Box,
	Button,
	Container,
	Flex,
	Heading,
	HStack,
	Link,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	VisuallyHidden,
} from '@chakra-ui/react'
import {FaWhatsapp} from 'react-icons/fa'
import {ReactNode} from 'react'
import React from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {AppState} from '../../reducers'
import {AccountStatus, UserState} from '../../types'

const SocialButton = ({
	children,
	label,
	href,
	...props
}: {
	children: ReactNode
	label: string
	href: string
}) => {
	return (
		<Link
			bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
			rounded={'full'}
			w={8}
			h={8}
			cursor={'pointer'}
			as={'a'}
			href={href}
			isExternal
			display={'inline-flex'}
			alignItems={'center'}
			justifyContent={'center'}
			transition={'background 0.3s ease'}
			_hover={{
				bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
			}}
		>
			<VisuallyHidden>{label}</VisuallyHidden>
			{children}
		</Link>
	)
}

export default function SmallCentered() {
	const linkColor = useColorModeValue('gray.600', 'gray.200')
	const linkHoverColor = useColorModeValue('gray.800', 'white')
	const {
		isAuthenticated,
		user: {status},
	} = useSelector((state: AppState) => state.auth)

	const location = useLocation()

	const shouldRedirectToPricing =
		isAuthenticated && status !== AccountStatus.VERIFIED
	const shouldRedirectToSignUp = !isAuthenticated

	const shouldExist = shouldRedirectToPricing || shouldRedirectToSignUp
	return (
		<Box>
			<Container
				as={Stack}
				maxW={'6xl'}
				py={8}
				spacing={4}
				justify={'center'}
				align={'center'}
			>
				{shouldExist && location.pathname === '/' && (
					<>
						<Stack
							direction={'column'}
							spacing={3}
							align={'center'}
							alignSelf={'center'}
							position={'relative'}
						>
							<Button
								bg={useColorModeValue('teal.500', 'teal.400')}
								color={useColorModeValue('white', '')}
								as={RouterLink}
								to={'/pricing'}
								size={'lg'}
								px={'5rem'}
								_hover={{
									bg: 'teal.500',
								}}
							>
								{shouldRedirectToPricing
									? 'Complete subscription'
									: 'See Plans'}
							</Button>
						</Stack>
					</>
				)}
			</Container>

			<Box
				borderTopWidth={1}
				borderStyle={'solid'}
				borderColor={useColorModeValue('gray.200', 'gray.700')}
			>
				<Container
					as={Stack}
					maxW={'6xl'}
					py={4}
					direction={{base: 'column', md: 'row'}}
					spacing={4}
					justify={{base: 'center', md: 'space-between'}}
					align={{base: 'center', md: 'center'}}
				>
					<Text fontWeight={500} color={linkColor}>
						© {new Date().getFullYear()} Easy Ecommerce. All rights reserved
					</Text>
					<HStack spacing={5}>
						{[
							{
								label: 'Terms and Conditions',
								href: '/terms',
							},
							{
								label: 'Privacy Policy',
								href: '/privacy',
							},
						].map((item, index) => (
							<Link
								to={item.href}
								fontWeight={500}
								color={linkColor}
								_hover={{
									textDecoration: 'none',
									color: linkHoverColor,
								}}
								key={index}
								as={RouterLink}
							>
								{item.label}
							</Link>
						))}
						<Box key={ContactLink.label}>
							<Link
								p={2}
								href={ContactLink.href}
								isExternal={ContactLink.external}
								fontWeight={500}
								color={linkColor}
								_hover={{
									textDecoration: 'none',
									color: linkHoverColor,
								}}
							>
								{ContactLink.label}
							</Link>
						</Box>
					</HStack>
				</Container>
			</Box>
		</Box>
	)
}
interface NavItem {
	label: string
	subLabel?: string
	children?: Array<NavItem>
	href?: string
}
const NAV_ITEMS: Array<NavItem> = [
	{
		label: 'Products',
		href: '/products',
	},
	{
		label: 'Stores',
		href: '/stores',
	},
	{
		label: 'Influencers',
		href: '/influencers',
	},
]

const ContactLink = {
	label: 'Contact us',
	href: 'mailto:info@kwefi.com',
	external: true,
}

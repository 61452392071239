import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {startInitializeProducts} from '../../actions'
import {AppState} from '../../reducers'
import {Footer, Header, Wrapper} from '../../components'

import {categories} from '../../data/categories'

import ProductCard from './ProductCard'
import {IProduct, SortingOptions} from '../../types'
import {
	Box,
	Button,
	Card,
	Grid,
	GridItem,
	Heading,
	Select,
	SimpleGrid,
	useColorModeValue,
	VStack,
} from '@chakra-ui/react'
import {FaCheck} from 'react-icons/fa'

const Index = () => {
	const dispatch = useDispatch()

	const {products, loading, length} = useSelector(
		(state: AppState) => state.products
	)
	const initFilterState = {
		category: '',
		isHot: false,
	}

	const [filters, setFilters] = useState(initFilterState)

	const [filtered, setFiltered] = useState<IProduct[]>(products)

	const handleFilters = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setFilters({...filters, category: e.target.value})
	}

	const toggleHot = () => {
		setFilters({...filters, isHot: !filters.isHot})
	}

	useEffect(() => {
		setFiltered(
			products.filter((product) => {
				const categoryMatch =
					filters.category === '' || product.category === filters.category
				const isHotMatch = !filters.isHot || (filters.isHot && product.isHot)
				return categoryMatch && isHotMatch
			})
		)
	}, [filters, products])

	const NewFilters = () => {
		return (
			<Card position={'sticky'} top={10} variant={'outline'} p="1rem">
				<VStack spacing={4} align="flex-start" dir="row">
					<Heading color={useColorModeValue('gray.800', 'gray.200')} size="md">
						Filters
					</Heading>
					<Select
						id="category"
						name="category"
						as={'select'}
						variant={'flushed'}
						value={filters.category}
						onChange={handleFilters}
					>
						<option selected value="">
							All Categories
						</option>
						{categories.map((item) => {
							return (
								<option
									className="capitalize"
									key={item.label}
									value={item.value}
								>
									{item.label}
								</option>
							)
						})}
					</Select>
					<Button
						colorScheme={!filters.isHot ? 'teal' : 'gray'}
						w="100%"
						my="1rem"
						fontSize={'sm'}
						onClick={toggleHot}
					>
						{filters.isHot ? 'Clear' : 'Show Seasonal Products'}
					</Button>
				</VStack>
			</Card>
		)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<Header />
			<Grid
				templateColumns={{
					base: 'repeat(1, 1fr)',
					md: 'repeat(2, 1fr)',
					lg: 'repeat(5, 1fr)',
				}}
				my="1rem"
				maxW={'7xl'}
				mx="auto"
			>
				<GridItem pos="relative" colSpan={1}>
					<NewFilters />
				</GridItem>
				<GridItem pl="1rem" colSpan={4}>
					<SimpleGrid mx="auto" columns={{base: 1, md: 2, lg: 3}} spacing={5}>
						{filtered.filter(Boolean).map((product) => (
							<ProductCard key={product._id} product={product} />
						))}
					</SimpleGrid>
				</GridItem>
			</Grid>
			<Footer />
		</>
	)
}
export default Index

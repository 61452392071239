import React, {useState} from 'react'
import {BaseLayout, Footer, Header} from '../../components'
import {Images} from '../../constants'
import {useHistory, useParams, Link as RouterLink} from 'react-router-dom'
import {resetPassword} from '../../services'
import {handleError} from '../../utils'
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Link,
	Button,
	Heading,
	Text,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react'
type IParams = {
	token: string
}
const Index = () => {
	const [newPassword, setNewPassword] = useState<string>('')
	const history = useHistory()
	const {token} = useParams<IParams>()
	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		resetPassword(newPassword, token)
			.then((res) => {
				history.push('/sign-in')
			})
			.catch(handleError)
	}

	return (
		<BaseLayout>
			<Flex align={'center'} justify={'center'}>
				<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
					<Stack align={'center'}>
						<Heading fontSize={'4xl'}>Sign in to your account</Heading>
						<Text fontSize={'lg'} color={'gray.600'}>
							to enjoy all of our cool <Link color={'teal.400'}>features</Link>{' '}
							✌️
						</Text>
					</Stack>
					<Box
						rounded={'lg'}
						bg={useColorModeValue('white', 'gray.700')}
						boxShadow={'lg'}
						p={8}
					>
						<form onSubmit={handleSubmit}>
							<Stack spacing={4}>
								<FormControl id="password" isRequired>
									<FormLabel>Password</FormLabel>
									<Input
										onChange={(e) => setNewPassword(e.target.value)}
										id="password"
										type="password"
									/>
								</FormControl>
								<Button
									bg={'teal.400'}
									color={'white'}
									_hover={{
										bg: 'teal.500',
									}}
									type="submit"
									disabled={newPassword.length < 6}
								>
									Confirm password
								</Button>
							</Stack>
						</form>
					</Box>
				</Stack>
			</Flex>
		</BaseLayout>
	)
}

export default Index

import {
	Card,
	CardBody,
	Stack,
	Heading,
	CardFooter,
	ButtonGroup,
	Button,
	Image,
	Text,
	Flex,
	Badge,
} from '@chakra-ui/react'
import React from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {AppState} from '../../reducers'
import {IProduct} from '../../types'
import {FaCalendarDay} from 'react-icons/fa'

interface CardProps {
	product: IProduct
}
const ProductCard: React.FC<CardProps> = (props) => {
	const {product} = props
	const history = useHistory()

	const {
		user: {favorites},
	} = useSelector((state: AppState) => state.auth)

	const isFavorite = (productId: string) => {
		return favorites.find((element) => element._id === productId)
	}

	const productLink = `/product/${product._id.slice(product?._id.length - 6)}`
	return (
		<Card variant={'elevated'} maxW="sm">
			<CardBody>
				<Image
					src={product.thumbnail}
					alt={product.title}
					borderRadius="lg"
					h={250}
					w={'100%'}
				/>
				<Stack mt="6" spacing="3">
					<Flex alignItems={'center'} justifyContent={'space-between'}>
						<Heading noOfLines={4} size="md">
							{product.title}
						</Heading>
						{product.isHot && <Badge colorScheme="purple">Seasonal</Badge>}
					</Flex>
					<Text
						noOfLines={1}
						dangerouslySetInnerHTML={{__html: product.description}}
					></Text>
					<ButtonGroup color="blue.600" fontSize="2xl">
						<Button pointerEvents={'none'} size={'xs'} variant="solid">
							Buy {product?.price.cost.max.toFixed(2)}$
						</Button>
						<Button pointerEvents={'none'} size={'xs'} variant="solid">
							Sell {product.price.selling.max.toFixed(2)}$
						</Button>
						<Button
							pointerEvents={'none'}
							size={'xs'}
							variant="solid"
							colorScheme="green"
						>
							Profit {product?.price.profit.max.toFixed(2)}$
						</Button>
					</ButtonGroup>
				</Stack>
			</CardBody>
			<CardFooter>
				<Button
					onClick={() => history.push(productLink)}
					width={'100%'}
					variant="solid"
					colorScheme="teal"
				>
					View details
				</Button>
			</CardFooter>
		</Card>
	)
}

export default ProductCard

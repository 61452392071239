const ApiConstants = {
	AUTH: {
		BASE_URL: 'https://api.easyecommerce.io/auth',
		SIGNUP: '/sign-up',
		SIGN_IN: '/sign-in',
		GOOGLE_SIGN_IN: '/google',
		FACEBOOK_SIGN_IN: '/facebook',
		RESET_PASSWORD: '/reset-password',
		FORGOT_PASSWORD: '/forgot-password',
	},
	UTILS: {
		BASE_URL: 'https://api.easyecommerce.io/utils',
		UPLOAD_FILE: '/upload',
		ADD_CONTACT: '/contact',
	},
	PAYMENTS: {
		ADD_NEW_PAYMENT_METHOD: '/payment',
		BASE_URL: 'https://api.easyecommerce.io/payments',
		CONFIG_STRIPE: '/config',
		SUBSCRIBE: '/init-sub',
		CANCEL_SUBSCRIPTION: '/cancel-subscription',
		REMOVE: '/payment',
		MAKE_DEFUALT: '/default-payment-method',
		UPGRADE_SUB: '/upgrade',
		VALIDATE_PROMO: '/promo',
	},
	PRODUCTS: {
		BASE_URL: 'https://api.easyecommerce.io/products',
		GET_ALL_PRODUCTS: '/',
		ADD_NEW_FAVORITE_PRODUCT: '/favorites',
		DELETE_FAVORITE_PRODUCT: '/favorites',
		ADD_NEW_PRODUCT: '/',
	},
	AMAZON_PRODUCTS: {
		BASE_URL: 'https://api.easyecommerce.io/amazon-products',
		GET_ALL_AMAZON_PRODUCTS: '/',
	},
	BUNDLES: {
		BASE_URL: 'https://api.easyecommerce.io/bundles',
		GET_ALL_BUNDLES: '/',
		GET_SINGLE_BUNDLE: '/bundle',
	},
	USERS: {
		BASE_URL: 'https://api.easyecommerce.io/users',
		ADD_NEW_FAVORITE_PRODUCT: '/favorites',
		DELETE_FAVORITE_PRODUCT: '/favorites',
		GET_ME: '/me',
		EDIT_PROFILE: '/edit-profile',
	},
	INFLUENCERS: {
		BASE_URL: 'https://api.easyecommerce.io/influencers',
		GET_ALL: '/',
		GET_SINGLE: '/influencer',
	},
	STORES: {
		BASE_URL: 'https://api.easyecommerce.io/stores',
		GET_ALL: '/',
	},
}
export default ApiConstants

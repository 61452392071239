import {
	FaFacebook,
	FaFacebookSquare,
	FaInstagram,
	FaInstagramSquare,
	FaPinterest,
	FaSnapchat,
	FaTiktok,
	FaYoutube,
	FaYoutubeSquare,
} from 'react-icons/fa'

const Socials = [
	{
		src: FaFacebook,
		name: 'Facebook',
	},
	{
		src: FaYoutube,
		name: 'Youtube',
	},
	{
		src: FaInstagram,
		name: 'Instagram',
	},

	{
		src: FaPinterest,
		name: 'Pinterest',
	},
	{
		src: FaTiktok,
		name: 'TikTok',
	},
	{
		src: FaSnapchat,
		name: 'Snapchat',
	},
	{
		src: FaInstagramSquare,
		name: 'IG Influencer',
	},
	{
		src: FaFacebookSquare,
		name: 'FB Influencer',
	},
	{
		src: FaTiktok,
		name: 'TikTok Influencer',
	},
	{
		src: FaYoutubeSquare,
		name: 'Youtube Influencer',
	},
]

export default Socials

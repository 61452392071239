import React from 'react'

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react'

interface IProps {
	title: string
	text: string
	variant: 'Info' | 'Warning' | 'Success'
	open: boolean
	buttonText: string
	action: () => void
	setOpen: () => void
}

const ConfirmationModal: React.FC<IProps> = (props) => {
	const {title, open, setOpen, buttonText, text, action} = props

	return (
		<Modal blockScrollOnMount={false} isOpen={open} onClose={setOpen}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text fontWeight="bold" mb="1rem">
						{text}
					</Text>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme="teal" mr={3} onClick={setOpen}>
						Close
					</Button>
					<Button onClick={action} variant="ghost">
						{buttonText}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
export default ConfirmationModal

import {Box, Flex, useColorModeValue} from '@chakra-ui/react'
import React, {useEffect, ReactNode, PropsWithChildren} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {Footer, Header} from '..'
import {AppState} from '../../reducers'
import {AccountStatus} from '../../types'

export const BaseLayout = ({children}: PropsWithChildren) => {
	const {isAuthenticated, user} = useSelector((state: AppState) => state.auth)
	const location = useLocation()

	useEffect(() => {
		if (
			isAuthenticated &&
			user.status !== AccountStatus.VERIFIED &&
			location.pathname.split('/')[1] !== 'checkout'
		) {
			push('/pricing')
		}
	}, [isAuthenticated, user.status])

	const {push} = useHistory()

	return (
		<>
			<Header />
			<Flex
				justifyContent="center"
				bg={useColorModeValue('gray.50', 'gray.900')}
				minHeight={'72vh'}
				p={'1rem'}
			>
				<Box maxW={{base: '100%', lg: '7xl'}}>{children}</Box>
			</Flex>
			<Footer />
		</>
	)
}

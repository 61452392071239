import {Box, Center, Spinner} from '@chakra-ui/react'
import React from 'react'

interface IProps {
	loading?: boolean
	children?: React.ReactNode
	classes?: string
	[x: string]: any
}
const Wrapper: React.FC<IProps> = ({loading, children}, restProp) => {
	if (loading) {
		return (
			<Center minHeight={'100vh'}>
				<Spinner />
			</Center>
		)
	}
	return <>{children}</>
}

export default Wrapper

import {
	Box,
	Flex,
	Heading,
	Spinner,
	Stack,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import React, {useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {clearTimeout} from 'timers'
import {BaseLayout} from '../../components'

const Index = () => {
	const {push} = useHistory()
	useEffect(() => {
		setTimeout(() => {
			push('/')
		}, 3000)
	})
	return (
		<Flex
			justifyContent={'center'}
			alignItems="center"
			minHeight={'100vh'}
			bg={useColorModeValue('gray.100', 'gray.900')}
		>
			<Stack textAlign={'center'} spacing={5}>
				<Heading
					color={useColorModeValue('gray.900', 'gray.100')}
					textTransform={'uppercase'}
					fontWeight={600}
					as={'h1'}
					size="xl"
				>
					Thank you for joining Easy Ecommerce.
				</Heading>
				<Text
					color={useColorModeValue('gray.700', 'gray.300')}
					fontWeight={600}
					size="md"
				>
					You will be redirected to the homepage in 3 seconds...{' '}
					<Spinner ml={'1rem'} textAlign={'center'} colorScheme={'gray'} />
				</Text>
			</Stack>
		</Flex>
	)
}
export default Index

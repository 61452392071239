import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Heading,
	SimpleGrid,
	Stack,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import React, {useEffect} from 'react'
import {BaseLayout} from '../../components'
import {Link as RouterLink, useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {AppState} from '../../reducers'
import {AccountStatus} from '../../types'
const Dashboard = () => {
	const {isAuthenticated, user} = useSelector((state: AppState) => state.auth)
	const {push} = useHistory()

	useEffect(() => {
		if (!isAuthenticated || user.status !== AccountStatus.VERIFIED) {
			push('/')
		}
	})

	console.log(user)

	const getNavItems = () => {
		if (user.bundleType === 'ONE_TIME') {
			return [
				{
					label: 'Products',
					href: '/products',
					content: 'New products are added daily and weekly.',
				},
				{
					label: 'Brands',
					href: '/stores',
					content: 'Explore new brands added daily and weekly.',
				},
			]
		} else {
			return [
				{
					label: 'Products',
					href: '/products',
					content: 'New products are added daily and weekly.',
				},
			]
		}
	}

	return (
		<BaseLayout>
			<Stack my={'2rem'}>
				<SimpleGrid
					spacing={'2rem'}
					maxH={'30vh'}
					maxW={'5xl'}
					columns={{base: 1, md: 1, lg: user.bundleType !== 'ONE_TIME' ? 1 : 2}}
				>
					{getNavItems().map((item, index) => (
						<TestimonialCard
							title={item.label}
							content={item.content}
							href={item.href}
							key={index}
							index={index}
						/>
					))}
				</SimpleGrid>
			</Stack>
		</BaseLayout>
	)
}

interface TestimonialCardProps {
	content: string
	title: string
	href: string
	index: number
}

function TestimonialCard(props: TestimonialCardProps) {
	const {title, content, href} = props
	return (
		<Card textAlign={'center'} p={'1rem'}>
			<CardHeader>
				<Heading
					fontWeight={600}
					color={useColorModeValue('gray.800', 'gray.200')}
					size={'xl'}
				>
					{title}
				</Heading>
			</CardHeader>
			<CardBody>
				<Text fontWeight={600}>{content}</Text>
			</CardBody>
			<CardFooter>
				<Button w={'100%'} as={RouterLink} to={href} colorScheme={'teal'}>
					Explore {title}
				</Button>
			</CardFooter>
		</Card>
	)
}
export default Dashboard

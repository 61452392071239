import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from '../../reducers'
import {Link as RouterLink, useHistory, useParams} from 'react-router-dom'
import {AccountStatus, BundleType, IStripeNewPaymentMethod} from '../../types'
import {BaseLayout} from '../../components'
import countries from '../../data/countries'
import {getSingleBundle, GetSingleBundle, subscribe} from '../../services'
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import {changeAccountType, changeBundleType} from '../../actions'
import {handleError} from '../../utils'
import {
	Box,
	Button,
	Card,
	Checkbox,
	Container,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Heading,
	HStack,
	Input,
	Link,
	List,
	ListItem,
	Select,
	Stack,
	Text,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react'
import {FaWhatsapp} from 'react-icons/fa'
import {Form} from 'react-final-form'

type Params = {
	priceId: string
}
const Checkout = () => {
	const {priceId} = useParams<Params>()

	const [isAcceptedTerms, setIsAcceptedTerms] = useState<boolean>(false)
	const {
		user: {email, fullName, _id},
	} = useSelector((state: AppState) => state.auth)
	const dispatch = useDispatch()
	const history = useHistory()
	const [loading, setLoading] = useState<boolean>(false)
	const [bundle, setBundle] = useState<GetSingleBundle.Prices>()
	const fetchBundle = useCallback(() => {
		setLoading(true)
		getSingleBundle(priceId)
			.then((res) => {
				setBundle(res?.data?.prices)
				setLoading(false)
			})
			.catch(handleError)
	}, [history, priceId])

	const [paymentData, setPaymentData] = useState<IStripeNewPaymentMethod>({
		city: '',
		country: '',
		line1: '',
		line2: '',
		state: '',
		postalCode: '',
	})

	const stripe = useStripe()
	const elements = useElements()
	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
			setPaymentData((prevState) => ({
				...prevState,
				[event.target.id]: event.target.value,
			}))
		},
		[setPaymentData]
	)
	const toast = useToast()

	const handleSubmit = () => {
		const {country, line1, line2, city, state} = paymentData
		if (!stripe || !elements) {
			console.error('Stripe or elements is not initialized')
			return
		}
		setLoading(true)

		const cardElement = elements.getElement(CardElement)
		if (!cardElement) {
			console.error('CardElement not found')
			setLoading(false)
			return
		}

		stripe
			.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					name: fullName,
					email: email,
					address: {
						city,
						country,
						line1,
						line2,
						state,
					},
				},
				metadata: {
					_id,
				},
			})
			.then((res) => {
				if (res.error) {
					throw res.error
				} else {
					return subscribe(priceId, res.paymentMethod.id)
				}
			})
			.then((res) => {
				console.log(res)
				dispatch(changeAccountType(AccountStatus.VERIFIED))
				dispatch(changeBundleType(res?.data?.user?.bundleType as BundleType))
				toast({
					title: 'Payment was done successfully!',
					position: 'top-right',
					isClosable: true,
					status: 'success',
				})
			})
			.catch((err) => {
				const errorMessage = err.response
					? err.response.data.error
					: err.message
				toast({
					title: `Payment is not complete, ${errorMessage}`,
					position: 'top-right',
					isClosable: true,
					status: 'error',
				})
				handleError(err)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (!priceId) {
			history.push('/404')
		} else {
			fetchBundle()
		}
		return () => fetchBundle()
	}, [priceId, history])

	const sortedCountries = countries
		.filter((item) => !item.suggested)
		.sort((a: any, b: any) => (a.label > b.label ? 1 : -1))
	const suggestedCountries = countries.filter((item) => item.suggested)

	return (
		<BaseLayout>
			<Flex justifyContent={'center'}>
				<Grid
					w={{base: '100%', lg: '7xl'}}
					gap={'2rem'}
					templateColumns="repeat(6, 1fr)"
				>
					<GridItem colSpan={{base: 6, lg: 4}}>
						<Card bg={useColorModeValue('white', 'gray.800')} p="2rem">
							<Stack spacing={5}>
								<Heading size="md" fontWeight={600}>
									Payment Details
								</Heading>
								<FormControl isReadOnly>
									<FormLabel htmlFor="email-address">
										Contact email address
									</FormLabel>
									<Input
										type="email"
										value={email}
										id="email-address"
										name="email-address"
									/>
								</FormControl>
								<FormControl
									border={'1px'}
									p="1rem"
									borderColor={'gray.200'}
									isRequired
									borderRadius={5}
								>
									<FormLabel htmlFor="card-element">Credit card</FormLabel>
									<CardElement
										options={{
											style: {
												invalid: {
													color: '#fa755a',
													iconColor: '#fa755a',
												},
												base: {
													color: useColorModeValue('', '#ffffff'),
													fontSize: '1.1rem',
												},
											},
										}}
									/>
								</FormControl>
								<Grid gap={'1rem'} templateColumns={'repeat(2, 1fr)'}>
									<GridItem>
										<FormControl isRequired>
											<FormLabel htmlFor="country">Country</FormLabel>
											<Select
												onChange={handleChange}
												name="country"
												id={'country'}
											>
												{suggestedCountries.map((item) => (
													<option value={item.code} key={item.code}>
														{item.label}
													</option>
												))}
												{sortedCountries.map((item) => (
													<option value={item.code} key={item.code}>
														{item.label}
													</option>
												))}
											</Select>
										</FormControl>
									</GridItem>
									<GridItem>
										<FormControl isRequired>
											<FormLabel htmlFor="line1">Address 1</FormLabel>
											<Input
												onChange={handleChange}
												type="text"
												id="line1"
												name="line1"
											/>
										</FormControl>
									</GridItem>
									<GridItem>
										<FormControl>
											<FormLabel htmlFor="line2">Address 2</FormLabel>
											<Input
												onChange={handleChange}
												id="line2"
												name="line2"
												type="text"
											/>
										</FormControl>
									</GridItem>
									<GridItem>
										<FormControl isRequired>
											<FormLabel htmlFor="state">State (or Province)</FormLabel>
											<Input
												onChange={handleChange}
												id="state"
												name="state"
												type="text"
											/>
										</FormControl>
									</GridItem>
									<GridItem>
										<FormControl isRequired>
											<FormLabel htmlFor="line2">City</FormLabel>
											<Input
												onChange={handleChange}
												id="city"
												name="city"
												type="text"
											/>
										</FormControl>
									</GridItem>
									<GridItem>
										<FormControl isRequired>
											<FormLabel htmlFor="postalCode">Post code</FormLabel>
											<Input
												onChange={handleChange}
												id="postalCode"
												name="postalCode"
												type="text"
											/>
										</FormControl>
									</GridItem>
								</Grid>

								<FormControl isRequired>
									<HStack spacing={4}>
										<Checkbox
											onChange={() =>
												setIsAcceptedTerms((prevState) => !prevState)
											}
											checked={isAcceptedTerms}
											fontWeight={600}
										/>
										<Text fontWeight={600}>
											I accept the website{' '}
											<Link
												color={useColorModeValue('blue.500', 'blue.200')}
												to={'/terms'}
												as={RouterLink}
											>
												Terms
											</Link>{' '}
											&{' '}
											<Link
												color={useColorModeValue('blue.500', 'blue.200')}
												to={'/privacy'}
												as={RouterLink}
											>
												Privary policy
											</Link>
										</Text>
									</HStack>
								</FormControl>

								<Button
									colorScheme={'teal'}
									isLoading={loading}
									onClick={handleSubmit}
									disabled={!isAcceptedTerms}
								>
									Confirm Payment
								</Button>
							</Stack>
						</Card>
					</GridItem>

					<GridItem colSpan={{base: 6, lg: 2}}>
						<Card bg={useColorModeValue('white', 'gray.800')} p="2rem">
							<Stack spacing={5}>
								<Heading size="md" fontWeight={600}>
									Invoice Details
								</Heading>
								<Flex justifyContent={'space-between'}>
									<Text
										color={useColorModeValue('gray.500', 'gray.300')}
										fontWeight={600}
									>
										Subscription price
									</Text>
									<Text
										color={useColorModeValue('gray.500', 'gray.300')}
										fontWeight={600}
									>
										{bundle?.unit_amount &&
											(bundle?.unit_amount / 100)?.toFixed(2)}
										$
									</Text>
								</Flex>
								<Flex justifyContent={'space-between'}>
									<Text
										color={useColorModeValue('gray.500', 'gray.300')}
										fontWeight={600}
									>
										Tax value
									</Text>
									<Text
										color={useColorModeValue('gray.500', 'gray.300')}
										fontWeight={600}
									>
										0$ (Inclusive)
									</Text>
								</Flex>
								<Divider />
								<Flex justifyContent={'space-between'}>
									<Text
										color={useColorModeValue('gray.500', 'gray.300')}
										fontWeight={600}
									>
										Total
									</Text>
									<Text
										color={useColorModeValue('gray.500', 'gray.300')}
										fontWeight={600}
									>
										{bundle?.unit_amount &&
											(bundle?.unit_amount / 100)?.toFixed(2)}
										$
									</Text>
								</Flex>
							</Stack>
						</Card>
						<Text p="2rem">
							You need help? Please contact us via{' '}
							<Flex
								alignItems={'center'}
								gap={'.5rem'}
								color={useColorModeValue('teal.500', 'teal.200')}
								_hover={{textDecoration: 'underline'}}
							>
								<Link
									href={'https://wa.me/message/KZDSMUABPQIQN1'}
									as={'a'}
									isExternal
									target="_blank"
								>
									WhatsApp
								</Link>
							</Flex>
						</Text>
					</GridItem>
				</Grid>
			</Flex>
		</BaseLayout>
	)
}
export default Checkout

import React from 'react'
import App from './App'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import {ChakraProvider} from '@chakra-ui/react'
import {PersistGate} from 'redux-persist/integration/react'
import {Elements} from '@stripe/react-stripe-js'
import {BrowserRouter} from 'react-router-dom'
import {Loading} from './components'
import {persistor, store} from './lib'
import {loadStripe} from '@stripe/stripe-js'
import {FetchStripeKey} from './services'
import {handleError} from './utils'

const app = document.getElementById('root')
const root = createRoot(app!)

FetchStripeKey()
	.then((res) => {
		const {publicKey} = res.data
		const stripePromise = loadStripe(publicKey)

		root.render(
			<Provider store={store}>
				<ChakraProvider>
					<PersistGate persistor={persistor} loading={<Loading />}>
						<Elements stripe={stripePromise}>
							<BrowserRouter>
								<App />
							</BrowserRouter>
						</Elements>
					</PersistGate>
				</ChakraProvider>
			</Provider>
		)
	})
	.catch(handleError)

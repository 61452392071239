import {
	Box,
	Button,
	Center,
	Heading,
	Stack,
	Text,
	Link as ChakraLink,
} from '@chakra-ui/react'
import React from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {BaseLayout} from '../../components'
import {AppState} from '../../reducers'
import {AccountStatus} from '../../types'

const Index = () => {
	const {
		isAuthenticated,
		user: {status},
	} = useSelector((state: AppState) => state.auth)
	const generateLink = () => {
		switch (isAuthenticated) {
			case true:
				if (status === AccountStatus.VERIFIED) {
					return {label: 'Dashboard', href: '/dashboard'}
				} else {
					return {label: 'Sign up', href: '/pricing'}
				}
			case false:
				return {label: 'Homepage', href: '/'}
		}
	}

	const ButtonText = (
		<Box>
			<Text px=".3rem" textTransform={'capitalize'} as="span">
				Go Back to {generateLink().label}
			</Text>
		</Box>
	)

	return (
		<BaseLayout>
			<Center minHeight={'70vh'}>
				<Stack spacing={5}>
					<Heading>404 Not found</Heading>
					<Button colorScheme={'teal'} as={Link} to={generateLink().href}>
						{ButtonText}
					</Button>
				</Stack>
			</Center>
		</BaseLayout>
	)
}

export default Index

import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
	AddPaymentMethodModal,
	Footer,
	Header,
	Wrapper,
	SuccessToast,
	ErrorToast,
	BaseLayout,
} from '../../components'
import {
	cancelSubscription,
	editProfile,
	getMyProfile,
	GetMyProfile,
	makeDefaultPaymentMethod,
	removePaymentMethod,
} from '../../services'
import {cancelSubscriptionAction} from '../../actions'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import moment from 'moment'
import {AccountStatus, BundleType, UserType} from '../../types'
import {Link} from 'react-router-dom'
import {TrashIcon} from '@heroicons/react/outline'
import {useHistory} from 'react-router-dom'
import {handleError} from '../../utils'
import {
	Button,
	Card,
	Center,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Stack,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react'

const Index = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [profileData, setProfileData] = useState<GetMyProfile.User>()
	const [loading, setLoading] = useState<boolean>(false)
	const [mainPaymentMethod, setMainPaymentMethod] = useState<string>('')
	const [successOpen, setSuccessOpen] = useState<boolean>(false)
	const [errorOpen, setErrorOpen] = useState<boolean>(false)
	const [declineReason, setDeclineReason] = useState<string>('')
	const [open, setOpen] = useState(false)
	const [confirmDefaultOpen, setConfirmDefaultOpen] = useState<boolean>(false)
	const [successDefaultOpen, setSuccessDefaultOpen] = useState<boolean>(false)

	const handleMakeDefault = useCallback(() => {
		setLoading(true)
		setConfirmDefaultOpen(false)
		makeDefaultPaymentMethod(mainPaymentMethod)
			.then((res) => {
				fetchProfile('default')
			})
			.catch(handleError)
	}, [mainPaymentMethod])
	const [cancelConfirmationModalOpen, setCancelSubscriptionModalOpen] =
		useState<boolean>(false)
	const fetchProfile = useCallback((type: 'load' | 'refetch' | 'default') => {
		setLoading(true)
		getMyProfile()
			.then((res) => {
				setProfileData(res?.data?.user)
				setLoading(false)
			})
			.catch(handleError)
		if (type === 'refetch') {
			setSuccessOpen(true)
		} else if (type === 'default') {
			setSuccessDefaultOpen(true)
		}
	}, [])

	useEffect(() => {
		fetchProfile('load')
		return () => fetchProfile('load')
	}, [])
	const handleCancel = useCallback(() => {
		setCancelSubscriptionModalOpen(false)
		if (profileData?.subscriptions.data[0].id) {
			cancelSubscription(profileData?.subscriptions.data[0].id)
				.then((res) => {
					fetchProfile('load')
					dispatch(cancelSubscriptionAction())
				})
				.catch((err) => {
					if (err.response) {
						console.log(err.response.data)
					} else {
						console.log(err)
					}
				})
		}
	}, [profileData, dispatch])
	const [removeConfirmationModalOpen, setRemoveConfirmationModalOpen] =
		useState<boolean>(false)
	const [successToastOpen, setSuccessToastOpen] = useState<boolean>(false)

	const handleRemovePaymentMethod = useCallback(() => {
		removePaymentMethod(mainPaymentMethod)
			.then((res) => {
				//@ts-ignore
				setProfileData((prevState) => ({
					...prevState,
					paymentMethods: {
						...prevState?.paymentMethods,
						data: prevState?.paymentMethods.data.filter(
							(item) => item.id !== mainPaymentMethod
						),
					},
				}))

				setSuccessToastOpen(true)
				setRemoveConfirmationModalOpen(false)
			})
			.catch((err) => {
				if (err.response) {
					console.log(err.response.data)
				} else {
					console.log(err)
				}
			})
	}, [mainPaymentMethod])

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			//@ts-ignore
			setProfileData((prevState) => ({
				...prevState,
				[event.target.id]: event.target.value,
			}))
		},
		[]
	)

	const toast = useToast()

	const handleSubmit = useCallback(
		(e: React.FormEvent) => {
			e.preventDefault()
			editProfile({
				fname: profileData?.fname || '',
				lname: profileData?.lname || '',
				password: profileData?.password || '',
			})
				.then(() => {
					fetchProfile('load')
					toast({
						title: 'You have updated your profile.',
						status: 'success',
						position: 'top-left',
					})
				})
				.catch((err) => {
					if (err.response) {
						toast({
							title: err.response.data.message,
							status: 'success',
							position: 'top-left',
						})
					} else {
						console.log(err)
					}
				})
		},
		[profileData]
	)

	return (
		<BaseLayout>
			<Center>
				<Card
					bg={useColorModeValue('#FFFFFF', 'gray.800')}
					w={{base: '100%', lg: '7xl'}}
					p={'1rem'}
					textAlign={'left'}
					color={useColorModeValue('gray.800', 'gray.100')}
				>
					<form onSubmit={handleSubmit}>
						<Stack spacing={6}>
							<Heading fontWeight={600} fontSize="2rem">
								Account Info
							</Heading>
							<FormControl isReadOnly>
								<FormLabel htmlFor="email">Email Address</FormLabel>
								<Input type={'email'} value={profileData?.email} id="email" />
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="fname">First name</FormLabel>
								<Input
									type={'text'}
									value={profileData?.fname}
									onChange={handleChange}
									id="fname"
								/>
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="lname">Last name</FormLabel>
								<Input
									type={'text'}
									value={profileData?.lname}
									onChange={handleChange}
									id="lname"
								/>
							</FormControl>
							<Button type="submit">Edit Profile</Button>
						</Stack>
					</form>
				</Card>
			</Center>
		</BaseLayout>
	)
}
export default Index

import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {startSignup} from '../../actions'
import {Link as RouterLink} from 'react-router-dom'
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Checkbox,
	Stack,
	Link,
	Button,
	Heading,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import {BaseLayout} from '../../components'

const Signout = () => {
	const dispatch = useDispatch()
	const [userData, setUserData] = useState({
		email: '',
		password: '',
		lname: '',
		fname: '',
	})
	const onSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		dispatch(startSignup({...userData}))
	}
	const queryParams = new URLSearchParams(window.location.search)

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setUserData((prevState) => ({
				...prevState,
				[event.target.id]: event.target.value,
			}))
		},
		[]
	)

	return (
		<BaseLayout>
			<Flex align={'center'} justify={'center'}>
				<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
					<Stack align={'center'}>
						<Heading fontSize={{base: '2xl', lg: '3xl'}}>
							Sign up to your new account
						</Heading>
						<Text fontSize={'lg'} color={'gray.600'}>
							to enjoy all of our cool <Link color={'teal.400'}>features</Link>{' '}
							✌️
						</Text>
					</Stack>
					<Box
						rounded={'lg'}
						bg={useColorModeValue('white', 'gray.700')}
						boxShadow={'lg'}
						p={8}
					>
						<form onSubmit={onSubmit}>
							<Stack spacing={4}>
								<FormControl id="fname">
									<FormLabel>First name</FormLabel>
									<Input onChange={handleChange} id="fname" type="text" />
								</FormControl>
								<FormControl id="lname">
									<FormLabel>Last name</FormLabel>
									<Input onChange={handleChange} id="lname" type="text" />
								</FormControl>
								<FormControl id="email">
									<FormLabel>Email address</FormLabel>
									<Input onChange={handleChange} id="email" type="email" />
								</FormControl>
								<FormControl id="password">
									<FormLabel>Password</FormLabel>
									<Input
										onChange={handleChange}
										id="password"
										type="password"
									/>
								</FormControl>
								<Stack spacing={5}>
									<Stack
										direction={{base: 'column', sm: 'row'}}
										align={'start'}
										justify={'space-between'}
									>
										<Link
											as={RouterLink}
											to="/forgot-password"
											color={'blue.400'}
										>
											Forgot password?
										</Link>
									</Stack>

									<Button
										bg={'teal.400'}
										color={'white'}
										_hover={{
											bg: 'teal.500',
										}}
										type="submit"
									>
										Sign up
									</Button>

									<Box>
										Already have an account?{' '}
										<Link as={RouterLink} to="/sign-in" color={'blue.400'}>
											Sign in
										</Link>
									</Box>
								</Stack>
							</Stack>
						</form>
					</Box>
				</Stack>
			</Flex>
		</BaseLayout>
	)
}

export default Signout

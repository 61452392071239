import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {loginAction, startGoogleLogin} from '../../actions'
import {GoogleLoginResponse} from 'react-google-login'
import {Link as RouterLink, useHistory} from 'react-router-dom'
import {BaseLayout} from '../../components'
import {login} from '../../services'
import {BundleType} from '../../types'
import Cookies from 'universal-cookie'
import {handleError} from '../../utils'
import {AppState} from '../../reducers'
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Link,
	Button,
	Heading,
	Text,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react'
import {FaGoogle} from 'react-icons/fa'

const Signin = () => {
	const history = useHistory()
	const {isAuthenticated} = useSelector((state: AppState) => state.auth)

	useEffect(() => {
		if (isAuthenticated) {
			history.push('/products')
		}
	}, [isAuthenticated])

	const [userData, setUserData] = useState({
		email: '',
		password: '',
	})
	const [errorLoginOpen, setErrorLoginOpen] = useState<boolean>(false)
	const dispatch = useDispatch()
	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setUserData((prevState) => ({
				...prevState,
				[event.target.id]: event.target.value,
			}))
		},
		[]
	)
	const cookies = new Cookies()
	const handleSubmit = useCallback(
		(event: React.FormEvent) => {
			event.preventDefault()
			// dispatch(startLogin(userData))
			const {email, password} = userData
			login(email.toLowerCase(), password)
				.then((res) => {
					const {
						token,
						fullName,
						email,
						_id,
						type,
						stripeId,
						activeSubscription,
						isTrialLegit,
						subscriptions,
						inTrial,
						paymentMethods,
						accountStatus,
						favorites,
						bundleType,
					} = res.data
					cookies.set('token', token, {path: '/'})
					cookies.set('fullName', fullName, {path: '/'})
					cookies.set('email', email, {path: '/'})
					cookies.set('_id', _id, {path: '/'})
					cookies.set('type', type, {path: '/'})
					cookies.set('stripeId', stripeId, {path: '/'})
					dispatch(
						loginAction({
							token,
							fullName,
							email,
							_id,
							type,
							stripeId,
							activeSubscription,
							subscriptions,
							inTrial,
							isTrialLegit,
							paymentMethods,
							status: accountStatus,
							favorites,
							bundleType: bundleType ? bundleType : BundleType.MONTHLY,
						})
					)
				})
				.catch((err) => {
					if (err.response) {
						setErrorLoginOpen(true)
						toast({
							title: 'Wrong email or password. Try again!',
							status: 'error',
							isClosable: true,
						})
						handleError(err)
					} else {
						handleError(err)
					}
				})
		},
		[userData]
	)
	const handleGoogleLogin = (googleData: GoogleLoginResponse) => {
		const {givenName, familyName, email} = googleData.profileObj
		dispatch(
			startGoogleLogin({fname: givenName, lname: familyName, email: email})
		)
	}
	//@ts-ignore
	const handleGoogleFailure = (result) => {
		alert(result)
	}

	const toast = useToast()

	return (
		<BaseLayout>
			<Flex align={'center'} justify={'center'}>
				<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
					<Stack align={'center'}>
						<Heading fontSize={'4xl'}>Sign in to your account</Heading>
						<Text fontSize={'lg'} color={'gray.600'}>
							to enjoy all of our cool{' '}
							<Text as={'span'} fontWeight={600}>
								features
							</Text>{' '}
							✌️
						</Text>
					</Stack>
					<Box
						rounded={'lg'}
						bg={useColorModeValue('white', 'gray.700')}
						boxShadow={'lg'}
						p={8}
					>
						<form onSubmit={handleSubmit}>
							<Stack spacing={4}>
								<FormControl id="email">
									<FormLabel>Email address</FormLabel>
									<Input onChange={handleChange} id="email" type="email" />
								</FormControl>
								<FormControl id="password">
									<FormLabel>Password</FormLabel>
									<Input
										onChange={handleChange}
										id="password"
										type="password"
									/>
								</FormControl>
								<Stack spacing={10}>
									<Stack
										direction={{base: 'column', sm: 'row'}}
										align={'start'}
										justify={'space-between'}
									>
										<Link
											as={RouterLink}
											to="/forgot-password"
											color={'teal.400'}
										>
											Forgot password?
										</Link>
									</Stack>
									<Button
										bg={'teal.400'}
										color={'white'}
										_hover={{
											bg: 'teal.500',
										}}
										type="submit"
									>
										Sign in
									</Button>
								</Stack>
							</Stack>
						</form>
					</Box>
				</Stack>
			</Flex>
		</BaseLayout>
	)
}

export default Signin

import React from 'react'
import {
	Box,
	Heading,
	Container,
	Text,
	Stack,
	AspectRatio,
	useColorModeValue,
} from '@chakra-ui/react'

const Cta = () => {
	return (
		<>
			<Container maxW={'3xl'}>
				<Stack
					as={Box}
					textAlign={'center'}
					spacing={{base: 8, md: 14}}
					pt={{base: 10, md: 20}}
					pb={{base: 10, md: 18}}
				>
					<Heading
						fontWeight={600}
						fontSize={{base: 'xl', sm: '2xl', md: '5xl'}}
						lineHeight={'110%'}
					>
						<Text mb={6}>
							Find your next winning product & best store design through
						</Text>
						<Text
							fontSize={{base: '4xl', lg: '6xl'}}
							fontWeight="bold"
							pb={'1rem'}
							color={useColorModeValue('teal.500', 'teal.300')}
						>
							Easy Ecommerce
						</Text>
					</Heading>
					<Text fontWeight={500} color={'gray.600'}>
						Get access to Mohamad Alkwefi ecommerce database within seconds.
					</Text>

					<AspectRatio ratio={16 / 9}>
						<iframe
							width="872"
							height="563"
							src="https://www.youtube.com/embed/Iyqp8Lv3DDE"
							title="EasyEcommerce.io شرح برنامج ايزي ايكومرس المحدث"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
						></iframe>
					</AspectRatio>
				</Stack>
			</Container>
		</>
	)
}

export default Cta

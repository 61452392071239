import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {BaseLayout} from '../../components'
import {
	Box,
	Container,
	Stack,
	Text,
	Image,
	Flex,
	VStack,
	Button,
	Heading,
	SimpleGrid,
	StackDivider,
	useColorModeValue,
	List,
	ListItem,
	ButtonGroup,
	Link,
	AspectRatio,
} from '@chakra-ui/react'
import {
	AmazonProduct as AmazonProductType,
	getAllAmazonProducts,
} from '../../services'
const AmazonProduct = () => {
	const {amazonProductId}: {amazonProductId: string} = useParams()
	const [product, setProduct] = useState<AmazonProductType>()

	const fetchProduct = () => {
		getAllAmazonProducts().then(({data}) => {
			const desired = data?.products?.filter(
				(item) => item._id === amazonProductId
			)

			setProduct(desired[0])
		})
	}
	useEffect(() => {
		if (!amazonProductId) {
			return
		}
		fetchProduct()
	}, [amazonProductId, fetchProduct])

	function numberWithCommas(x: number) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}
	return (
		<BaseLayout>
			<Container maxW={'7xl'}>
				<SimpleGrid
					columns={{base: 1, lg: 2}}
					spacing={{base: 8, md: 10}}
					py={{base: 18, md: 24}}
				>
					<Flex pos="relative">
						<Box>
							<Image
								rounded={'md'}
								alt={'product image'}
								src={product?.thumbnail}
								fit={'cover'}
								align={'center'}
								w={'100%'}
								height="auto"
								maxH={'80vh'}
							/>
						</Box>
					</Flex>
					<Stack spacing={{base: 6, md: 10}}>
						<Box as={'header'}>
							<Box>
								<Heading
									lineHeight={1.1}
									fontWeight={600}
									fontSize={'lg'}
									mb="1rem"
								>
									Average Monthly Sales:{' '}
									<Text color={'teal'} as={'span'}>
										{numberWithCommas(product?.revenue || 0)}
									</Text>
								</Heading>
							</Box>

							<Heading
								lineHeight={1.1}
								fontWeight={600}
								fontSize={{base: '2xl', sm: '4xl', lg: '5xl'}}
							>
								{product?.title}
							</Heading>
							<ButtonGroup mt={'1rem'} color="blue.600" fontSize="2xl">
								<Button
									pointerEvents={'none'}
									size={'xs'}
									variant="solid"
									colorScheme="orange"
								>
									Buy {product?.price.cost.min.toFixed(2)}$ -{' '}
									{product?.price.cost.max.toFixed(2)}$
								</Button>
								<Button
									pointerEvents={'none'}
									size={'xs'}
									variant="solid"
									colorScheme="green"
								>
									Sell {product?.price.selling.min.toFixed(2)}${' '}
									{product?.price.selling.max.toFixed(2)}$
								</Button>
							</ButtonGroup>
						</Box>

						<Stack
							spacing={{base: 4, sm: 6}}
							direction={'column'}
							divider={
								<StackDivider
									borderColor={useColorModeValue('gray.200', 'gray.600')}
								/>
							}
						>
							<Box>
								<Text
									fontSize={{base: '16px', lg: '18px'}}
									color={useColorModeValue('teal.500', 'teal.300')}
									fontWeight={'500'}
									textTransform={'uppercase'}
									mb={'4'}
								>
									Suppliers Links
								</Text>

								<List spacing={2}>
									{product?.supplierLinks.filter(Boolean).map((item, index) => (
										<ListItem key={index}>
											<Link noOfLines={1} isExternal href={item}>
												{item}
											</Link>
										</ListItem>
									))}
								</List>
							</Box>
							<Box>
								<Text
									fontSize={{base: '16px', lg: '18px'}}
									color={useColorModeValue('teal.500', 'teal.300')}
									fontWeight={'500'}
									textTransform={'uppercase'}
									mb={'4'}
								>
									Competitors Study
								</Text>
								<Box>
									<Text
										dangerouslySetInnerHTML={{
											//@ts-ignore
											__html: product?.competitorLinks,
										}}
									/>
								</Box>
							</Box>
						</Stack>
					</Stack>
				</SimpleGrid>
			</Container>
		</BaseLayout>
	)
}

export default AmazonProduct

import React, {useEffect, useState} from 'react'
import {BaseLayout} from '../../components'
import {useSelector} from 'react-redux'
import {AppState} from '../../reducers'
import {AccountStatus} from '../../types'
import {GetAllBundles, getAllBundles} from '../../services'
import {handleError} from '../../utils'
import {
	Box,
	Stack,
	HStack,
	Heading,
	Text,
	VStack,
	useColorModeValue,
	List,
	ListItem,
	ListIcon,
	Button,
	Card,
} from '@chakra-ui/react'
import {FaCheckCircle} from 'react-icons/fa'
import {Link as RouterLink} from 'react-router-dom'

function PriceWrapper({children}: {children: React.ReactNode}) {
	return (
		<Box mb={4} alignSelf={{base: 'center', lg: 'flex-start'}}>
			{children}
		</Box>
	)
}

const Index = () => {
	const {
		user: {status},
		isAuthenticated,
	} = useSelector((state: AppState) => state.auth)
	const [bundles, setBundles] = useState<GetAllBundles.Price[]>([])
	const [loading, setLoading] = useState<boolean>(false)

	const fetchBundles = () => {
		setLoading(true)
		getAllBundles()
			.then((res) => {
				setBundles(
					res?.data?.prices.filter((item) => item.nickname !== 'upgrade')
				)
				setLoading(false)
			})
			.catch(handleError)
	}
	useEffect(() => {
		fetchBundles()
		return () => fetchBundles()
	}, [])

	const lists = [
		'Access to winning products',
		'Access to brand store designs',
		'Limited time offer: free one product page review, worth $150',
	]

	let sortedProducts = bundles.sort((p1, p2) =>
		p1.unit_amount > p2.unit_amount
			? 1
			: p1.unit_amount < p2.unit_amount
			? -1
			: 0
	)
	return (
		<BaseLayout>
			<Box py={12}>
				<VStack spacing={2} textAlign="center">
					<Heading as="h1" fontWeight={700} fontSize="4xl">
						Select a plan
					</Heading>
					<Text fontSize="lg" color={'gray.500'}>
						Cancel anytime. No contracts. No hidden fees.
					</Text>
				</VStack>
				<Stack
					direction={{base: 'column', md: 'row'}}
					textAlign="center"
					justify="center"
					spacing={{base: 5, lg: 10}}
					py={10}
				>
					{sortedProducts.map((tier, index) => {
						return (
							<React.Fragment key={tier?.id}>
								{tier?.product?.metadata?.main_key === 'production' && (
									<PriceWrapper>
										<Card
											borderWidth={
												tier?.product?.metadata?.period === 'Lifetime'
													? '2px'
													: '1px'
											}
											borderRadius={'xl'}
											borderColor={
												tier?.product?.metadata?.period === 'Lifetime'
													? useColorModeValue('#FFD700', '#AA6C39')
													: useColorModeValue('gray.200', 'gray.500')
											}
											position="relative"
										>
											<Box
												position="absolute"
												top="-16px"
												left="50%"
												style={{transform: 'translate(-50%)'}}
											>
												{tier?.product?.metadata?.period === 'Lifetime' && (
													<Text
														textTransform="uppercase"
														bg={useColorModeValue('#FFD700', '#AA6C39')}
														px={3}
														py={1}
														color={useColorModeValue('gray.900', 'gray.300')}
														fontSize="sm"
														fontWeight="600"
														rounded="xl"
													>
														Best seller
													</Text>
												)}
											</Box>
											<Box pt={8} pb={4} px={12}>
												<Text fontWeight="500" fontSize="2xl">
													{tier?.product?.name}
												</Text>
												<HStack justifyContent="center">
													<Text fontSize="2xl" fontWeight="600">
														$
													</Text>
													<Text fontSize="4xl" fontWeight="900">
														{tier?.unit_amount / 100}
													</Text>
													<Text fontSize="2xl" color="gray.500">
														/ {tier?.product?.metadata?.period}
													</Text>
												</HStack>
											</Box>
											<VStack
												bg={useColorModeValue('gray.50', 'gray.700')}
												py={4}
												borderBottomRadius={'xl'}
											>
												{tier?.product?.name === 'Basic Plan' ? (
													<List spacing={3} textAlign="start" px={12}>
														{['Access to winning products'].map((feature) => (
															<ListItem fontWeight={600} key={tier?.id}>
																<ListIcon
																	as={FaCheckCircle}
																	color="green.500"
																/>
																{feature}
															</ListItem>
														))}
													</List>
												) : (
													<List spacing={3} textAlign="start" px={12}>
														{lists.map((feature) => (
															<ListItem fontWeight={600} key={tier?.id}>
																<ListIcon
																	as={FaCheckCircle}
																	color="green.500"
																/>
																{feature}
															</ListItem>
														))}
													</List>
												)}

												<Box w="80%" pt={7}>
													<Button
														colorScheme={'teal'}
														to={
															isAuthenticated
																? status === AccountStatus.VERIFIED
																	? '/dashboard'
																	: `/checkout/${tier?.id}`
																: '/sign-up'
														}
														as={RouterLink}
														w="full"
													>
														Subscribe
													</Button>
												</Box>
											</VStack>
										</Card>
									</PriceWrapper>
								)}
							</React.Fragment>
						)
					})}
				</Stack>
			</Box>
		</BaseLayout>
	)
}
export default Index

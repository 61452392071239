import {
	Box,
	Button,
	Card,
	Center,
	Grid,
	GridItem,
	Heading,
	Image,
	Select,
	SimpleGrid,
	Stack,
	Text,
	useColorModeValue,
	VStack,
} from '@chakra-ui/react'
import React, {useCallback, useEffect, useState} from 'react'
import {BaseLayout} from '../../components'
import {getAllAmazonProducts, AmazonProduct} from '../../services'
import {Link as RouterLink} from 'react-router-dom'
import {categories} from '../../data/categories'
import {FaCheck} from 'react-icons/fa'

const AmazonProducts = () => {
	const [products, setProducts] = useState<AmazonProduct[]>()

	const [loading, setLoading] = useState(true)
	const fetchProducts = useCallback(() => {
		getAllAmazonProducts()
			.then((res) => {
				setProducts(res.data.products)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const [filters, setFilters] = useState('')

	useEffect(() => {
		fetchProducts()
		window.scrollTo(0, 0)
	}, [fetchProducts])

	const handleTestedClick = () => {
		if (filters === 'tested') {
			setFilters('')
			fetchProducts()
		} else {
			setFilters('tested')
			setProducts((products) =>
				products?.filter(
					(item) => item.category.toLocaleLowerCase() === 'tested'
				)
			)
		}
	}

	const NewFilters = () => {
		return (
			<Card position={'sticky'} top={10} variant={'outline'} p="1rem">
				<VStack spacing={4} align="flex-start" dir="row">
					<Button
						colorScheme={filters === 'tested' ? 'teal' : 'gray'}
						w="100%"
						leftIcon={filters === 'tested' ? <FaCheck /> : undefined}
						my="1rem"
						onClick={handleTestedClick}
					>
						{filters === 'tested' ? 'Clear selection' : 'Seasonal products'}
					</Button>
				</VStack>
			</Card>
		)
	}

	return (
		<BaseLayout>
			<SimpleGrid
				my="1rem"
				maxW={'7xl'}
				columns={{base: 1, md: 2, lg: 3}}
				spacing={5}
			>
				{products?.map((item) => (
					<AmazonCard key={item?._id} product={item} />
				))}
			</SimpleGrid>
		</BaseLayout>
	)
}
function numberWithCommas(x: number) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
const AmazonCard = ({product}: {product: AmazonProduct}) => (
	<Center py={12}>
		<Box
			role={'group'}
			p={6}
			maxW={'330px'}
			w={'full'}
			bg={useColorModeValue('white', 'gray.800')}
			boxShadow={'lg'}
			rounded={'lg'}
			pos={'relative'}
			zIndex={1}
		>
			<Box
				rounded={'lg'}
				mt={-12}
				pos={'relative'}
				height={'230px'}
				_groupHover={{
					_after: {
						filter: 'blur(10px)',
					},
				}}
			>
				<Image
					rounded={'lg'}
					height={230}
					width={282}
					objectFit={'cover'}
					src={product.thumbnail}
				/>
			</Box>
			<Stack spacing={5}>
				<Stack pt={10} align={'center'}>
					<Text
						fontWeight={600}
						color={'gray.500'}
						fontSize={'sm'}
						textTransform={'uppercase'}
					>
						{product.category}
					</Text>
					<Heading fontSize={'lg'} fontFamily={'body'} fontWeight={600}>
						{product.title}
					</Heading>
					<Stack direction={'row'} align={'center'}>
						<Text color={'gray'} fontWeight={600}>
							Avg. Monthly Sales:{' '}
							<Text as={'span'} color={'teal'}>
								{numberWithCommas(product.revenue)}
							</Text>
						</Text>
					</Stack>
				</Stack>
				<Button
					mt={{base: '1rem', lg: '2rem'}}
					as={RouterLink}
					to={`/amazon-product/${product._id}`}
				>
					Show Info
				</Button>
			</Stack>
		</Box>
	</Center>
)

export default AmazonProducts

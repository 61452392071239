import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from '../../reducers'
import {startInitializeProducts} from '../../actions'
import {IProduct} from '../../types'
import {AddToFavorites, BaseLayout, Footer, Header} from '../../components'
import {Socials} from '../../constants'
import {
	Box,
	Container,
	Stack,
	Text,
	Image,
	Flex,
	VStack,
	Button,
	Heading,
	SimpleGrid,
	StackDivider,
	useColorModeValue,
	List,
	ListItem,
	ButtonGroup,
	Link,
} from '@chakra-ui/react'

type Params = {
	productId: string
}

//@ts-ignore
function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const Index = () => {
	const {productId} = useParams<Params>()
	const history = useHistory()
	const {products: StateProducts} = useSelector(
		(state: AppState) => state.products
	)
	const [product, setProduct] = useState<IProduct>()
	const dispatch = useDispatch()
	const fetchProduct = useCallback(() => {
		dispatch(startInitializeProducts())
		const productHolder = StateProducts.find(
			(item) => item._id.slice(item?._id.length - 6) === productId
		)
		if (!productHolder) {
			history.push('/404')
		} else {
			setProduct(productHolder)
		}
	}, [productId])
	useEffect(() => {
		if (!productId) {
			history.push('/404')
		} else {
			fetchProduct()
		}
		return () => fetchProduct()
	}, [productId])

	const [shouldExpand, setshouldExpand] = useState(false)

	const handleToggle = () => setshouldExpand((shouldExpand) => !shouldExpand)

	return (
		<BaseLayout>
			<Container maxW={'7xl'}>
				<SimpleGrid
					columns={{base: 1, lg: 2}}
					spacing={{base: 8, md: 10}}
					py={{base: 18, md: 24}}
				>
					<VStack spacing={'1rem'}>
						<Image
							rounded={'md'}
							alt={'product image'}
							src={product?.thumbnail}
							fit={'cover'}
							align={'center'}
							w={'100%'}
							h={{base: '100%', sm: '400px', lg: '500px'}}
						/>
						<AddToFavorites productId={product?._id || ''} />
					</VStack>
					<Stack spacing={{base: 6, md: 10}}>
						<Box as={'header'}>
							<Heading
								lineHeight={1.1}
								fontWeight={600}
								fontSize={{base: '2xl', sm: '4xl', lg: '5xl'}}
							>
								{product?.title}
							</Heading>
							<ButtonGroup mt={'1rem'} color="blue.600" fontSize="2xl">
								<Button
									pointerEvents={'none'}
									size={'xs'}
									variant="solid"
									colorScheme="orange"
								>
									Buy {product?.price.cost.max.toFixed(2)}$
								</Button>
								<Button
									pointerEvents={'none'}
									size={'xs'}
									variant="solid"
									colorScheme="yellow"
								>
									Sell {product?.price.selling.max.toFixed(2)}$
								</Button>
								<Button
									pointerEvents={'none'}
									size={'xs'}
									variant="solid"
									colorScheme="green"
								>
									Profit {product?.price.profit.max.toFixed(2)}$
								</Button>
							</ButtonGroup>
						</Box>

						<Stack
							spacing={{base: 4, sm: 6}}
							direction={'column'}
							divider={
								<StackDivider
									borderColor={useColorModeValue('gray.200', 'gray.600')}
								/>
							}
						>
							<VStack spacing={{base: 4, sm: 6}}>
								<Box>
									<Text
										fontSize={{base: '16px', lg: '18px'}}
										color={useColorModeValue('teal.500', 'teal.300')}
										fontWeight={'500'}
										textTransform={'uppercase'}
										mb={'4'}
									>
										Details
									</Text>
									<Text
										//@ts-ignore
										dangerouslySetInnerHTML={{__html: product?.description}}
										noOfLines={!shouldExpand ? 3 : undefined}
									></Text>
									<Button
										size="sm"
										variant="link"
										fontWeight="bold"
										colorScheme="slate"
										textDecoration="underline"
										onClick={handleToggle}
									>
										{shouldExpand ? 'Show less' : 'Read more'}
									</Button>
								</Box>
							</VStack>
						</Stack>
					</Stack>
				</SimpleGrid>
			</Container>
		</BaseLayout>
	)
}
export default Index

import React, {Fragment, useEffect, useState} from 'react'
import {BaseLayout, Footer, Header} from '../../components'
import {Cta, Testimonials} from '../../components/home'
import {useSelector} from 'react-redux'
import {AppState} from '../../reducers'
import {AccountStatus, UserState} from '../../types'
import {useHistory} from 'react-router-dom'
import {Divider, useColorModeValue} from '@chakra-ui/react'

const Index = () => {
	const {
		user: {email, status},
		isAuthenticated,
	} = useSelector((state: AppState) => state.auth)

	const {push} = useHistory()

	useEffect(() => {
		if (isAuthenticated && status === AccountStatus.VERIFIED) {
			push('/dashboard')
		}
	}, [isAuthenticated, status])

	return (
		<BaseLayout>
			<Cta />
		</BaseLayout>
	)
}
export default Index

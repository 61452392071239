import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Center,
	Heading,
	Link,
	Select,
	SimpleGrid,
	Stack,
	Text,
	useColorModeValue,
	VStack,
} from '@chakra-ui/react'
import React, {useCallback, useEffect, useState} from 'react'
import {BaseLayout} from '../../components'
import {categories} from '../../data/categories'
import {GetStores, getStores} from '../../services'
import {useSelector} from 'react-redux'
import {AppState} from '../../reducers'
import {useHistory} from 'react-router-dom'

const Stores: React.FC = () => {
	const [stores, setStores] = useState<GetStores.Store[]>()
	const [error, setError] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const [filtered, setFiltered] = useState<GetStores.Store[]>()

	enum StoreType {
		DROPSHIPPING = 'dropshipping',
		BRAND = 'brand',
		AMAZON = 'amazon',
	}

	const {user} = useSelector((state: AppState) => state.auth)

	const history = useHistory()

	// const handleAmazonClick = () => {
	// 	if (filters.type === StoreType.AMAZON) {
	// 		setFilters({type: ''})
	// 	} else {
	// 		setFilters({type: StoreType.AMAZON})
	// 	}
	// }
	// const handleDropshippingClick = () => {
	// 	if (filters.type === StoreType.DROPSHIPPING) {
	// 		setFilters({type: ''})
	// 	} else {
	// 		setFilters({type: StoreType.DROPSHIPPING})
	// 	}
	// }
	// const handleBrandClick = () => {
	// 	if (filters.type === StoreType.BRAND) {
	// 		setFilters(initFilterState)
	// 	} else {
	// 		setFilters({type: StoreType.BRAND})
	// 	}
	// }

	// const handleClearAll = () => {
	// 	setFilters({type: ''})
	// }

	const initFilterState = {
		type: '',
	}
	const [filters, setFilters] = useState(initFilterState)
	const fetchData = useCallback(() => {
		setLoading(true)
		getStores()
			.then((res) => {
				setStores(res?.data.data)
				setFiltered(res?.data.data)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
				setError(err.data.message)
			})
	}, [])

	useEffect(() => {
		if (user.bundleType !== 'ONE_TIME') {
			history.push('/')
		}
	}, [])

	useEffect(() => {
		if (Object.values(filters).every((item) => item === '')) {
			return fetchData()
		} else {
			setFiltered(stores)
			Object.entries(filters).forEach(([key, value]) => {
				if (value !== '') {
					return setFiltered((data) =>
						//@ts-ignore
						data?.filter((item) => item[key] === value)
					)
				}
			})
		}
	}, [fetchData, filters, setFilters])

	const getColor = (str: string) => {
		switch (str) {
			case StoreType.AMAZON:
				return useColorModeValue('orange.600', 'orange.200')
			case StoreType.DROPSHIPPING:
				return useColorModeValue('green.600', 'green.200')
			case StoreType.BRAND:
				return useColorModeValue('blue.600', 'blue.200')
		}
	}

	const handleCategoryFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const category = e.target.value
		if (category === '') {
			setFiltered(stores)
		} else {
			setFiltered(stores?.filter((item) => item.category === category))
		}
	}
	console.log(filtered)
	return (
		<BaseLayout>
			<Stack spacing={5}>
				<Card variant={'outline'} p="1rem">
					<VStack spacing={4} align="flex-start" dir="row">
						<Heading
							color={useColorModeValue('gray.800', 'gray.200')}
							size="md"
						>
							Filters
						</Heading>
						<Select
							id="category"
							name="category"
							as={'select'}
							variant={'flushed'}
							onChange={handleCategoryFilter}
						>
							<option selected value="">
								All Categories
							</option>
							{categories.map((item) => {
								return (
									<option
										className="capitalize"
										key={item.label}
										value={item.value}
									>
										{item.label}
									</option>
								)
							})}
						</Select>
					</VStack>
				</Card>
				<>
					{!!filtered?.length ? (
						<SimpleGrid spacing={4} columns={{base: 1, md: 2, lg: 4}}>
							{filtered?.map((item) => (
								<Card key={item._id}>
									<CardHeader borderBottom={'1px solid gray'}>
										<Heading
											fontWeight={600}
											color={useColorModeValue('gray.800', 'gray.200')}
											size="md"
											textTransform={'capitalize'}
											textAlign="center"
										>
											{item.name}
										</Heading>
									</CardHeader>
									<CardBody pb="3rem">
										<Stack spacing={3} textAlign={'center'}>
											<Text
												size="sm"
												color={useColorModeValue('teal.600', 'teal.300')}
												fontWeight={600}
												textTransform={'capitalize'}
											>
												{item.category}
											</Text>
											<Text
												noOfLines={2}
												size="sm"
												dangerouslySetInnerHTML={{__html: item.description}}
											/>
										</Stack>
									</CardBody>
									<CardFooter>
										<Button w="100%" as={Link} href={item.link} isExternal>
											Visit {item.name}
										</Button>
									</CardFooter>
								</Card>
							))}
						</SimpleGrid>
					) : (
						<SimpleGrid>
							<Center height={'50vh'}>
								<Heading>No products match your filter.</Heading>
							</Center>
						</SimpleGrid>
					)}
				</>
			</Stack>
		</BaseLayout>
	)
}

export default Stores

import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
	startAddToFavorites,
	startInitializeProducts,
	startRemoveFavorites,
} from '../../actions'
import {AppState} from '../../reducers'
import {Link} from 'react-router-dom'
import {BaseLayout, Footer, Header} from '../../components'
import {Socials} from '../../constants'
import moment from 'moment'
import {categories} from '../../data/categories'
import ProductCard from '../Products/ProductCard'
import {Center, Heading, SimpleGrid} from '@chakra-ui/react'

const Index = () => {
	const dispatch = useDispatch()
	const fetchProducts = useCallback(() => {
		dispatch(startInitializeProducts())
	}, [])
	useEffect(() => {
		fetchProducts()
		return () => fetchProducts()
	}, [])
	const {products, loading} = useSelector((state: AppState) => state.products)
	const findImage = useCallback(
		(item: string) => Socials.find((i) => i.name == item)?.src,
		[]
	)

	const {
		user: {favorites},
	} = useSelector((state: AppState) => state.auth)

	const isFavorite = (productId: string) => {
		return favorites.find((element) => element._id === productId)
	}

	const findCategory = (item: string) => {
		return categories.find((i) => i.value == item.toLowerCase())?.label
	}
	return (
		<BaseLayout>
			<Center maxW={{base: undefined, lg: '7xl'}} pl="1rem">
				{!!favorites.length ? (
					<SimpleGrid mx="auto" columns={{base: 1, md: 2, lg: 3}} spacing={5}>
						{favorites.filter(Boolean).map((product) => (
							<ProductCard key={product._id} product={product} />
						))}
					</SimpleGrid>
				) : (
					<Heading>Your favorite products list is empty.</Heading>
				)}
			</Center>
		</BaseLayout>
	)
}
export default Index

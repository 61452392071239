import React, {useState} from 'react'
import {Footer, Header} from '../../components'
import {Images} from '../../constants'
import {forgotPassword} from '../../services'
import {CheckCircleIcon, XIcon} from '@heroicons/react/solid'
import {handleError} from '../../utils'
import {
	Button,
	FormControl,
	Flex,
	Heading,
	Input,
	Stack,
	Text,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react'

const Index = () => {
	const [email, setEmail] = useState<string>('')
	const toast = useToast()
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		setLoading(true)
		forgotPassword(email)
			.then(() => {
				setIsSubmitted(true)
				toast({
					title: 'Nice! Please check your email.',
					position: 'top-right',
					status: 'success',
				})
			})
			.catch(() =>
				toast({
					title: 'Error submitting your request, try again later.',
					position: 'top-right',
					status: 'error',
				})
			)
			.finally(() => setLoading(false))
	}

	return (
		<>
			<Flex
				minH={'100vh'}
				align={'center'}
				justify={'center'}
				bg={useColorModeValue('gray.50', 'gray.800')}
			>
				<Stack
					spacing={4}
					w={'full'}
					maxW={'md'}
					bg={useColorModeValue('white', 'gray.700')}
					rounded={'xl'}
					boxShadow={'lg'}
					p={6}
					my={12}
				>
					<Heading lineHeight={1.1} fontSize={{base: '2xl', md: '3xl'}}>
						Forgot your password?
					</Heading>
					<Text
						fontSize={{base: 'sm', sm: 'md'}}
						color={useColorModeValue('gray.800', 'gray.400')}
					>
						You&apos;ll get an email with a reset link
					</Text>
					<form onSubmit={handleSubmit}>
						<Stack spacing={6}>
							<FormControl id="email" isRequired>
								<Input
									placeholder="your-email@example.com"
									_placeholder={{color: 'gray.500'}}
									onChange={(e) => setEmail(e.target.value)}
									type="email"
								/>
							</FormControl>
							<Button
								bg={'teal.400'}
								color={'white'}
								_hover={{
									bg: 'teal.500',
								}}
								type="submit"
								disabled={loading}
							>
								{loading ? 'Loading...' : 'Reset password'}
							</Button>
						</Stack>
					</form>
				</Stack>
			</Flex>
		</>
	)
}

export default Index

import {AxiosResponse} from 'axios'
import {ApiConstants} from '../constants'
import {StoresAxios} from '../lib'

export const getStores = (): Promise<AxiosResponse<GetStores.RootObject>> => {
	return StoresAxios({
		method: 'GET',
		url: ApiConstants.STORES.GET_ALL,
	})
}

export declare namespace GetStores {
	export interface Store {
		_id: string
		name: string
		category: string
		description: string
		link: string
		type: string | null
		createdAt: Date
		updatedAt: Date
	}

	export interface RootObject {
		message: string
		data: Store[]
	}
}

import {Button} from '@chakra-ui/react'
import {HeartIcon} from '@heroicons/react/outline'
import {HeartIcon as HeartIconFilled} from '@heroicons/react/solid'

import React, {Fragment, useCallback, useMemo} from 'react'
import {FaHeart, FaHeartBroken} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {startAddToFavorites, startRemoveFavorites} from '../../actions'
import {AppState} from '../../reducers'

interface IProps {
	productId: string
}

const AddToFavorites: React.FC<IProps> = (props) => {
	const dispatch = useDispatch()
	const {
		user: {favorites},
	} = useSelector((state: AppState) => state.auth)
	const {productId} = props

	const isFavorite = useMemo(() => {
		return favorites.find((item) => item._id === productId)
	}, [productId, favorites])

	const handleAddFavorite = () => {
		dispatch(startAddToFavorites(productId))
	}

	const handleDeleteFavorite = () => {
		dispatch(startRemoveFavorites(productId))
	}
	return (
		<>
			{!isFavorite ? (
				<Button
					mt="1rem"
					variant={'outline'}
					colorScheme="red"
					rightIcon={<FaHeart />}
					onClick={handleAddFavorite}
				>
					Add to favorite
				</Button>
			) : (
				<Button
					onClick={handleDeleteFavorite}
					mt="1rem"
					colorScheme="red"
					rightIcon={<FaHeartBroken />}
				>
					Remove from favorite
				</Button>
			)}
		</>
	)
}

export default AddToFavorites

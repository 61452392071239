import React from 'react'
import {Spinner, Center} from '@chakra-ui/react'

const Wrapper: React.FC = () => {
	return (
		<Center style={{height: '100vh'}}>
			<Spinner colorScheme={'teal'} />
		</Center>
	)
}

export default Wrapper
